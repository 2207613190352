import styled from "@emotion/styled";
import "@fontsource/comic-neue";
import confetti from "canvas-confetti";
import { StaticImage } from "gatsby-plugin-image";
import "normalize.css";
import * as React from "react";

// styles
const Page = styled.main({
  color: "#232129",
  padding: "0 16px",

  fontFamily: "Comic Neue",
  fontSize: "16px",

  "> div": {
    boxSizing: "border-box",
  },
});

const Header = styled.div({
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  overflowX: "auto",
  padding: "16px 0",

  fontFamily: "Comic Neue",
  fontSize: "32px",
  fontWeight: "bolder",
});

const Footer = styled.div({
  width: "100%",
  paddingBottom: "32px",
});

const Signature = styled.div({
  display: "flex",
  justifyContent: "space-between",
  fontWeight: "bold",
});

const Text = styled.div({
  textAlign: "center",
  padding: "16px 0",
});

const Photos = styled.div({
  display: "flex",
  flexWrap: "wrap",
  gap: "8px",

  // height: "400px",
  // overflowY: "scroll",

  "> div": {
    flexGrow: 1,
  },
});

// Confetti

var duration = 20 * 1000;
var animationEnd = Date.now() + duration;
var defaults = { startVelocity: 15, spread: 360, ticks: 60, zIndex: 0 };

function randomInRange(min, max) {
  return Math.random() * (max - min) + min;
}

var interval = setInterval(function () {
  var timeLeft = animationEnd - Date.now();

  if (timeLeft <= 0) {
    return clearInterval(interval);
  }

  var particleCount = 50 * (timeLeft / duration);
  // since particles fall down, start a bit higher than random
  confetti(
    Object.assign({}, defaults, {
      particleCount,
      origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
    })
  );
  confetti(
    Object.assign({}, defaults, {
      particleCount,
      origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
    })
  );
}, 250);

// Markup

const IndexPage = () => {
  return (
    <Page>
      <title>Hi Ryan</title>
      <Header>Happy Birthday, Ryan!</Header>
      <Photos>
        <StaticImage
          src="../images/espresso.jpg"
          placeholder="blurred"
          layout="constrained"
          width={300}
        />
      </Photos>
      <Text>You're an intrepid adventurer...</Text>
      <Photos>
        <StaticImage
          src="../images/salty.jpg"
          placeholder="blurred"
          layout="constrained"
          width={300}
        />
        <StaticImage
          src="../images/hike.jpeg"
          placeholder="blurred"
          layout="constrained"
          width={300}
        />
        <StaticImage
          src="../images/beatch.jpeg"
          placeholder="blurred"
          layout="constrained"
          width={300}
        />
      </Photos>
      <Text>... a loyal, fun, and caring friend ...</Text>
      <Photos>
        <StaticImage
          src="../images/boys.jpg"
          placeholder="blurred"
          layout="constrained"
          width={300}
        />
        <StaticImage
          src="../images/karaoke.jpeg"
          placeholder="blurred"
          layout="constrained"
          width={300}
        />
        <StaticImage
          src="../images/nye.jpg"
          placeholder="blurred"
          layout="constrained"
          width={300}
        />
      </Photos>
      <Text>... a true creative ...</Text>
      <Photos>
        <StaticImage
          src="../images/foil.jpg"
          placeholder="blurred"
          layout="constrained"
          width={300}
        />
        <StaticImage
          src="../images/shoe.jpeg"
          placeholder="blurred"
          layout="constrained"
          width={300}
        />
        <StaticImage
          src="../images/sit.JPG"
          placeholder="blurred"
          layout="constrained"
          width={300}
        />
      </Photos>
      <Text>... and the best boyfriend a girl could ask for!</Text>
      <Photos>
        <StaticImage
          src="../images/bless.jpg"
          placeholder="blurred"
          layout="constrained"
          width={300}
        />
      </Photos>
      <Footer>
        <Text>
          PS: This isn't as much your actual gift as it is a legally binding
          promise to a 50 hours of my time to actually help you build a proper
          folio.
        </Text>
        <Signature>
          <div>29 April 2021</div>
          <div>Love, Anna</div>
        </Signature>
      </Footer>
    </Page>
  );
};

export default IndexPage;
